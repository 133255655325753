var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "occupational" },
    [
      _c(
        "Row",
        [
          _c(
            "Collapse",
            {
              staticClass: "abnormalInformationDiv",
              on: { "on-change": _vm.abnormalInformationChange },
              model: {
                value: _vm.abnormalInfoValue,
                callback: function ($$v) {
                  _vm.abnormalInfoValue = $$v
                },
                expression: "abnormalInfoValue",
              },
            },
            [
              _c(
                "Panel",
                {
                  attrs: { name: "1" },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c(
                            "Card",
                            [
                              _c(
                                "Form",
                                [
                                  _vm.personInfo.id && _vm.tabooList.length > 0
                                    ? _c(
                                        "Row",
                                        {
                                          staticStyle: {
                                            "flex-wrap": "nowrap",
                                          },
                                        },
                                        [
                                          _c(
                                            "Col",
                                            {
                                              staticStyle: {
                                                "min-width": "120px",
                                              },
                                              attrs: { span: "6" },
                                            },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  staticStyle: { color: "red" },
                                                  attrs: { label: "检查类别:" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.personInfo
                                                        .work_state_text
                                                    ) + " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "18" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  staticStyle: { color: "red" },
                                                  attrs: {
                                                    label:
                                                      "接触的职业病危害及因素：",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.personInfo
                                                          .hazard_factors_text
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._l(
                                    _vm.tabooListNames,
                                    function (item, index) {
                                      return _c("Row", [
                                        item.taboo &&
                                        item.taboo.trim().length > 0
                                          ? _c("b", [
                                              _vm._v(_vm._s(item.name) + ":"),
                                            ])
                                          : _vm._e(),
                                        item.taboo &&
                                        item.taboo.trim().length > 0
                                          ? _c("b", [_vm._v("职业禁忌证:")])
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#0ac1c7" } },
                                          [_vm._v(_vm._s(item.taboo))]
                                        ),
                                      ])
                                    }
                                  ),
                                  _vm.inquirys && _vm.inquirys.trim().length > 0
                                    ? _c("Row", [
                                        _c("b", [_vm._v("实验室和其他检查:")]),
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#0ac1c7" } },
                                          [_vm._v(_vm._s(_vm.inquirys))]
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [_vm._v(" 检查类别 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Form",
        { staticStyle: { display: "flex" }, attrs: { labelWidth: 120 } },
        [
          _c(
            "FormItem",
            { attrs: { label: "总检医生:" } },
            [
              _c(
                "Select",
                {
                  staticClass: "input",
                  attrs: {
                    filterable: true,
                    "label-in-value": "",
                    transfer: "",
                  },
                  on: { "on-select": _vm.doctorSelectChange },
                  model: {
                    value: _vm.doctorId,
                    callback: function ($$v) {
                      _vm.doctorId = $$v
                    },
                    expression: "doctorId",
                  },
                },
                _vm._l(_vm.userArray, function (itemD, index) {
                  return _c(
                    "Option",
                    {
                      key: index,
                      attrs: { value: itemD.id, label: itemD.nickname },
                    },
                    [_vm._v(" " + _vm._s(itemD.nickname) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "总检日期：" } },
            [
              _c("DatePicker", {
                staticStyle: { width: "175px" },
                attrs: {
                  type: "datetime",
                  transfer: "",
                  placeholder: "请选择总检日期",
                },
                model: {
                  value: _vm.inspectForm.inspectionDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.inspectForm, "inspectionDate", $$v)
                  },
                  expression: "inspectForm.inspectionDate",
                },
              }),
            ],
            1
          ),
          _c(
            "Button",
            {
              staticStyle: { "margin-left": "30px" },
              attrs: { type: "primary" },
              on: { click: _vm.resultSummary },
            },
            [_vm._v("异常结果")]
          ),
        ],
        1
      ),
      _c(
        "Row",
        [
          _c(
            "Collapse",
            {
              staticClass: "conclusionDiv",
              model: {
                value: _vm.clusionInfo,
                callback: function ($$v) {
                  _vm.clusionInfo = $$v
                },
                expression: "clusionInfo",
              },
            },
            [
              _c(
                "Panel",
                {
                  attrs: { name: "1" },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c(
                            "Row",
                            [
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "Card",
                                    {
                                      staticClass: "card",
                                      scopedSlots: _vm._u([
                                        {
                                          key: "title",
                                          fn: function () {
                                            return [
                                              _vm._v(" 结果及建议 "),
                                              _vm.firstPersonId &&
                                              _vm.firstPersonId.trim().length >
                                                0
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "cardResultButton",
                                                      attrs: {
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.firstPersonConclusionClick,
                                                      },
                                                    },
                                                    [
                                                      _c("Icon", {
                                                        attrs: {
                                                          type: "arrow-graph-down-right",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        " 第一次体检结论 "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.firstPersonId &&
                                              _vm.firstPersonId.trim().length >
                                                0
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "cardResultButton",
                                                      attrs: {
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.firstPersonItemClick,
                                                      },
                                                    },
                                                    [
                                                      _c("Icon", {
                                                        attrs: {
                                                          type: "arrow-graph-down-right",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        " 第一次明细结果 "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _c(
                                        "Form",
                                        {
                                          staticClass: "clusionInfoForm",
                                          attrs: { labelWidth: 100 },
                                        },
                                        [
                                          _c(
                                            "Checkbox",
                                            {
                                              model: {
                                                value: _vm.isDifferenceModel,
                                                callback: function ($$v) {
                                                  _vm.isDifferenceModel = $$v
                                                },
                                                expression: "isDifferenceModel",
                                              },
                                            },
                                            [_vm._v("目前未见异常")]
                                          ),
                                          _c("div"),
                                          _vm.isDifferenceModel
                                            ? _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "危害因素" },
                                                },
                                                [
                                                  _c(
                                                    "Select",
                                                    {
                                                      staticStyle: {
                                                        width: "260px",
                                                      },
                                                      attrs: { multiple: "" },
                                                      on: {
                                                        "on-change":
                                                          _vm.getConclusionCode,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.noDifferenceModel,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.noDifferenceModel =
                                                            $$v
                                                        },
                                                        expression:
                                                          "noDifferenceModel",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.hazardFactorsList,
                                                      function (item, i) {
                                                        return _c(
                                                          "Option",
                                                          {
                                                            key: i,
                                                            attrs: {
                                                              value: item.code,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.name
                                                              ) + " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "Checkbox",
                                            {
                                              model: {
                                                value: _vm.isReviewModel,
                                                callback: function ($$v) {
                                                  _vm.isReviewModel = $$v
                                                },
                                                expression: "isReviewModel",
                                              },
                                            },
                                            [_vm._v("复查")]
                                          ),
                                          _vm.isReviewModel
                                            ? _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "危害因素" },
                                                },
                                                [
                                                  _c(
                                                    "Select",
                                                    {
                                                      staticStyle: {
                                                        width: "260px",
                                                      },
                                                      attrs: {
                                                        multiple: "",
                                                        transfer: "",
                                                      },
                                                      on: {
                                                        "on-change":
                                                          _vm.getConclusionCode,
                                                      },
                                                      model: {
                                                        value: _vm.reviewModel,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.reviewModel = $$v
                                                        },
                                                        expression:
                                                          "reviewModel",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.hazardFactorsList,
                                                      function (item, i) {
                                                        return _c(
                                                          "Option",
                                                          {
                                                            key: i,
                                                            attrs: {
                                                              value: item.code,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.name
                                                              ) + " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c("div"),
                                          _c(
                                            "Checkbox",
                                            {
                                              on: {
                                                "on-change": _vm.changeDiseases,
                                              },
                                              model: {
                                                value: _vm.isDiseases,
                                                callback: function ($$v) {
                                                  _vm.isDiseases = $$v
                                                },
                                                expression: "isDiseases",
                                              },
                                            },
                                            [_vm._v("疑似职业病")]
                                          ),
                                          _vm.isDiseases
                                            ? _c(
                                                "div",
                                                { staticClass: "diseasesDiv" },
                                                _vm._l(
                                                  _vm.diseasesList,
                                                  function (itemD, i) {
                                                    return _c(
                                                      "Row",
                                                      [
                                                        _c(
                                                          "Col",
                                                          {
                                                            attrs: {
                                                              span: "8",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "Checkbox",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    itemD.name,
                                                                },
                                                                on: {
                                                                  "on-change":
                                                                    _vm.changeDiseases,
                                                                },
                                                                model: {
                                                                  value:
                                                                    itemD.isCheck,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        itemD,
                                                                        "isCheck",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "itemD.isCheck",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    itemD.name
                                                                  ) + " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "Col",
                                                          {
                                                            attrs: {
                                                              span: "16",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "Select",
                                                              {
                                                                staticStyle: {
                                                                  width:
                                                                    "260px",
                                                                },
                                                                attrs: {
                                                                  multiple: "",
                                                                  transfer: "",
                                                                },
                                                                on: {
                                                                  "on-change":
                                                                    _vm.getConclusionCode,
                                                                },
                                                                model: {
                                                                  value:
                                                                    itemD.diseasesModel,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        itemD,
                                                                        "diseasesModel",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "itemD.diseasesModel",
                                                                },
                                                              },
                                                              _vm._l(
                                                                itemD.diseases,
                                                                function (
                                                                  itemS,
                                                                  j
                                                                ) {
                                                                  return _c(
                                                                    "Option",
                                                                    {
                                                                      key: j,
                                                                      attrs: {
                                                                        value:
                                                                          itemS.code,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          itemS.name
                                                                        ) + " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                          _c("div"),
                                          _c(
                                            "Checkbox",
                                            {
                                              on: {
                                                "on-change": _vm.tabooDiseases,
                                              },
                                              model: {
                                                value: _vm.isTaboo,
                                                callback: function ($$v) {
                                                  _vm.isTaboo = $$v
                                                },
                                                expression: "isTaboo",
                                              },
                                            },
                                            [_vm._v("职业禁忌证")]
                                          ),
                                          _vm.isTaboo
                                            ? _c(
                                                "div",
                                                { staticClass: "tabooDiv" },
                                                _vm._l(
                                                  _vm.tabooList,
                                                  function (item, i) {
                                                    return _c(
                                                      "Row",
                                                      [
                                                        _c(
                                                          "Col",
                                                          {
                                                            attrs: {
                                                              span: "8",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "Checkbox",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    item.name,
                                                                },
                                                                on: {
                                                                  "on-change":
                                                                    _vm.tabooDiseases,
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.isCheck,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "isCheck",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.isCheck",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.name
                                                                  ) + " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "Col",
                                                          {
                                                            attrs: {
                                                              span: "16",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "Select",
                                                              {
                                                                staticStyle: {
                                                                  width:
                                                                    "260px",
                                                                },
                                                                attrs: {
                                                                  multiple: "",
                                                                  transfer: "",
                                                                },
                                                                on: {
                                                                  "on-change":
                                                                    _vm.getConclusionCode,
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.tabooModel,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "tabooModel",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.tabooModel",
                                                                },
                                                              },
                                                              _vm._l(
                                                                item.taboo,
                                                                function (
                                                                  itemS,
                                                                  j
                                                                ) {
                                                                  return _c(
                                                                    "Option",
                                                                    {
                                                                      key: j,
                                                                      attrs: {
                                                                        value:
                                                                          itemS.code,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          itemS.name
                                                                        ) + " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                          _c("div"),
                                          _c(
                                            "Checkbox",
                                            {
                                              model: {
                                                value:
                                                  _vm.isOtherExceptionsModel,
                                                callback: function ($$v) {
                                                  _vm.isOtherExceptionsModel =
                                                    $$v
                                                },
                                                expression:
                                                  "isOtherExceptionsModel",
                                              },
                                            },
                                            [_vm._v("其他疾病或异常")]
                                          ),
                                          _vm.isOtherExceptionsModel
                                            ? _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "危害因素" },
                                                },
                                                [
                                                  _c(
                                                    "Select",
                                                    {
                                                      staticStyle: {
                                                        width: "260px",
                                                      },
                                                      attrs: { multiple: "" },
                                                      on: {
                                                        "on-change":
                                                          _vm.getConclusionCode,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.otherExceptionsModel,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.otherExceptionsModel =
                                                            $$v
                                                        },
                                                        expression:
                                                          "otherExceptionsModel",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.hazardFactorsList,
                                                      function (item, i) {
                                                        return _c(
                                                          "Option",
                                                          {
                                                            key: i,
                                                            attrs: {
                                                              value: item.code,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.name
                                                              ) + " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isOtherExceptionsModel
                                            ? _c(
                                                "FormItem",
                                                { attrs: { label: "描述" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "textareaResult",
                                                      staticStyle: {
                                                        position: "relative",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "Button",
                                                        {
                                                          staticClass: "choice",
                                                          staticStyle: {
                                                            left: "0px",
                                                          },
                                                          attrs: {
                                                            type: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleWordChoose(
                                                                "zjqtjcycjg"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("选 ")]
                                                      ),
                                                      _c("Input", {
                                                        staticClass:
                                                          "cardTextarea",
                                                        staticStyle: {
                                                          width: "90%",
                                                        },
                                                        attrs: {
                                                          type: "textarea",
                                                          autosize: {
                                                            minRows: 5,
                                                            maxRows: 10,
                                                          },
                                                          placeholder: "",
                                                        },
                                                        on: {
                                                          "on-change":
                                                            _vm.otherExceptionsChange,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.otherExceptions,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.otherExceptions =
                                                              $$v
                                                          },
                                                          expression:
                                                            "otherExceptions",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c("div"),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "Card",
                                    {
                                      staticClass: "card",
                                      attrs: { title: "结果及建议" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "title",
                                          fn: function () {
                                            return [
                                              _vm._v(" 结果及建议 "),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "cardResultButton",
                                                  attrs: { type: "primary" },
                                                  on: {
                                                    click: _vm.cardResultClick,
                                                  },
                                                },
                                                [
                                                  _c("Icon", {
                                                    attrs: {
                                                      type: "arrow-graph-down-right",
                                                    },
                                                  }),
                                                  _vm._v(" 智能总检 "),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _c("Row", [
                                        _c("div", { staticClass: "txt" }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "red",
                                                "font-size": "16px",
                                                position: "relative",
                                                top: "4px",
                                              },
                                            },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("职业健康体检结果 "),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "cardTextareaResult" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "textareaResult",
                                                staticStyle: {
                                                  position: "relative",
                                                },
                                              },
                                              [
                                                _c(
                                                  "Button",
                                                  {
                                                    staticClass: "choice",
                                                    staticStyle: {
                                                      left: "0px",
                                                    },
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleWordChoose(
                                                          "zjzyjcycjg"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("选 ")]
                                                ),
                                                _c("Input", {
                                                  staticClass: "cardTextarea",
                                                  attrs: {
                                                    type: "textarea",
                                                    autosize: {
                                                      maxRows: 12,
                                                      minRows: 8,
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.inspectForm
                                                        .conclusion,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.inspectForm,
                                                        "conclusion",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "inspectForm.conclusion",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("Row", [
                                        _c("div", { staticClass: "txt" }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "red",
                                                "font-size": "16px",
                                                position: "relative",
                                                top: "4px",
                                              },
                                            },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("职业健康体检建议 "),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "cardTextareaResult" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "textareaResult",
                                                staticStyle: {
                                                  position: "relative",
                                                },
                                              },
                                              [
                                                _c(
                                                  "Button",
                                                  {
                                                    staticClass: "choice",
                                                    staticStyle: {
                                                      left: "0px",
                                                    },
                                                    attrs: { type: "primary" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleWordChoose(
                                                          "zjclyj"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("选 ")]
                                                ),
                                                _c("Input", {
                                                  staticClass: "cardTextarea",
                                                  attrs: {
                                                    type: "textarea",
                                                    autosize: {
                                                      maxRows: 12,
                                                      minRows: 8,
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.inspectForm
                                                        .handleOpinion,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.inspectForm,
                                                        "handleOpinion",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "inspectForm.handleOpinion",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("div", { staticStyle: { width: "200px" } }, [
                    _vm._v("职业体检结论"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("drawer", {
        attrs: {
          width: "400",
          modalTitle: _vm.title,
          type: _vm.type,
          inspectForm: _vm.inspectForm,
          inspectType:
            _vm.personInfo.physical_type || _vm.personInfo.physicalType,
          personInfo: _vm.personInfo,
          hazardFactors:
            _vm.personInfo.hazard_factors || _vm.personInfo.hazardFactors,
          hazardFactorsText:
            _vm.personInfo.hazard_factors_text ||
            _vm.personInfo.hazardFactorsText,
          isMultiple: _vm.isMultiple,
        },
        on: { getSelectedWords: _vm.getSelectedWords },
        model: {
          value: _vm.wordChooseShow,
          callback: function ($$v) {
            _vm.wordChooseShow = $$v
          },
          expression: "wordChooseShow",
        },
      }),
      _c("positiveResult", {
        attrs: { personId: _vm.personId },
        model: {
          value: _vm.modalResult,
          callback: function ($$v) {
            _vm.modalResult = $$v
          },
          expression: "modalResult",
        },
      }),
      _c("project-details", {
        attrs: {
          checkDoctor: _vm.doctorInfo.id,
          tableData: _vm.firstTableData,
          title: "明细结果查看",
          "person-info": _vm.personInfo,
          "person-id": _vm.reviewFirstPersonId,
        },
        model: {
          value: _vm.projectDetailReviewShow,
          callback: function ($$v) {
            _vm.projectDetailReviewShow = $$v
          },
          expression: "projectDetailReviewShow",
        },
      }),
      _c("conclusion-examination", {
        attrs: {
          hazardFactorsList: _vm.hazardFactorsList,
          "person-id": _vm.reviewFirstPersonId,
          "modal-title": "第一次体检结论",
        },
        model: {
          value: _vm.conclusionExaminationShow,
          callback: function ($$v) {
            _vm.conclusionExaminationShow = $$v
          },
          expression: "conclusionExaminationShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }